
import './styles.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import logo from '././assets/sw-logo.svg'
import { BrowserRouter,Routes,Route,Navigate, useLocation } from 'react-router-dom';
import GuestNavbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Trainings from './pages/Trainings';
import Account from './pages/Account';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavbarFooterRoutes = ['/payment'];

  return (
    <>
      {!hideNavbarFooterRoutes.includes(location.pathname) && <GuestNavbar />}
      <div>{children}</div>
      {!hideNavbarFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};


function App() {


  return (
    <BrowserRouter>
     <ToastContainer className='mx-2 mx-md-0 mt-3 mt-md-0' position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{zIndex:'11000'}}/>
  
    <ScrollToTop/>
    <Layout>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/trainings" element={<Trainings />} />
    <Route path="/payment" element={<Account />} />
    <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </Layout>
  
    </BrowserRouter>
   
  );
}

export default App;
