import React, {useState, useEffect, useRef} from 'react'
import { Button,Card, Row, Col, Image, Form, Carousel } from 'react-bootstrap'
import arrowGiphy from '../assets/arrow-giphy.svg'
import arrowRight from '../assets/arrow-right.svg'
import technologyGiphy from '../assets/technology-giphy.svg'
import giphy from '../assets/giphy-3.svg'
import boxBg from '../assets/box-background.png'
import idea1 from '../assets/reality-icon-1.svg'
import idea2 from '../assets/reality-icon-2.svg'
import idea3 from '../assets/reality-icon-3.svg'
import phone1 from '../assets/overlay-phone1.svg'
import phone2 from '../assets/overlay-phone2.svg'
import laptop1 from '../assets/laptop1.svg'
import laptop2 from '../assets/laptop2.svg'
import virtue from '../assets/virtueshop-logo.svg'
import stock from '../assets/stockit-logo.svg'
import roppi from '../assets/roppi.svg'
import faramove from '../assets/faramove.svg'
import wave1 from '../assets/wave.png'
import wave2 from '../assets/wave2.png'
import wave3 from '../assets/wave3.svg'
import wave4 from '../assets/wave4.svg'
import tool1 from '../assets/tool-1.svg'
import tool2 from '../assets/tool-2.svg'
import tool3 from '../assets/tool-3.svg'
import client from '../assets/ai-image1.svg'
import client2 from '../assets/ai-image2.svg'
import client3 from '../assets/ai-image3.svg'
import rate from '../assets/Rate.svg'
import advantage1 from '../assets/advantage-icon1.svg'
import advantage2 from '../assets/advantage-icon2.svg'
import advantage3 from '../assets/advantage-icon3.svg'
import training from '../assets/product-team.svg'
import { useAppContext } from '../AppContext'
import sarah from '../assets/sarah.jpeg'
import toyosi from '../assets/toyosi.jpg'
import olajumoke from '../assets/olajumoke.JPG'
import emailjs from 'emailjs-com';
import olanike from '../assets/olanike-bit.png'
import fara from '../assets/fara-bitmoji.png'
import { useNavigate } from 'react-router-dom'

const curriculum = ["Intro to PM","Product Led Growth", "Product Ideation & Research", "Product Launch","User-Focused Design", "Product Analytics","Your role in Product Development", "Product Market Fit",]

const reviews = [
    {
    id:1,
    title:"SW PM Trainee",
    image:olajumoke,
    name:'Olajumoke Odunsi',
    testimonyTitle:'2024 Cohort',
    testimony:"Our daily sessions are always enlightening, and I look forward to attending each one. The trainer's deep knowledge and engaging teaching style make complex concepts easy to understand. I also appreciated the interactive segments, which effectively reinforced my learning. I highly recommend this training to anyone seeking to gain experience in product management. It’s been a game-changer for me, and I’m so glad I participated."
    },
    {
        id:2,
        title:"SW PM Trainee",
        image:client2,
        name:'Tinuade Adeola',
        testimonyTitle:'2024 Cohort',
        testimony:'SalesWave has been an incredible journey. Sarah, our tutor coordinator, was a guiding force throughout the entire process. She made sure I didn’t skip any important steps, even when I felt hesitant or unprepared. Each class was engaging, and Sarah’s feedback was always constructive and delivered with care. She ensured that I worked through every task and assignment, even when I felt it was tough. I particularly appreciate how she taught me to embrace goal orientation and how to present myself with confidence. It’s clear that every topic was treated with utmost importance, and I truly took ownership of my learning.'
        },
        {
            id:3,
            title:"SW PM Trainee",
            image:toyosi,
            name:'Toyosi ',
            testimonyTitle:'2024 Cohort',
            testimony:"The Saleswave training program was a truly transformative experience for me as a product manager. It combined practical application with insightful theory, offering an engaging and collaborative environment through discussions, case studies, and hands-on problem-solving. The curriculum was thoughtfully designed, covering everything from market analysis to leveraging advanced features, leaving me feeling confident and well-prepared. What truly stood out were the facilitators, whose expertise and real-world insights made the learning relatable and impactful. This program not only sharpened my skills but also inspired me to innovate and deliver greater value . It is truly an unforgettable journey. Thank you 💃💃💃💃🎉🎉🥳🥳"
            },
        
]

const benefits = [
    {
    id:1,
    title:'4 WEEKS TRAINING',
    icon:tool1,
    text:'In just 4 weeks, you’ll gain the skills and confidence to excel in product management.'
    },
    {
        id:2,
        title:'4 WEEKS INTERNSHIP',
        icon:tool1,
        text:"Top performers during training will earn a spot in our exclusive 4-week internship"
        },
        {
            id:3,
            title:'FULL TIME ROLE',
            icon:tool1,
            text:"Top performers who excel in the program and internship will be considered for full-time roles within our organization."
            },
            {
                id:4,
                title:'REAL WORLD PRODUCT',
                icon:tool1,
                text:'Launch a real product and gain experience working with cross-functional teams'
                },
                {
                    id:5,
                    title:'100% VIRTUAL',
                    icon:tool1,
                    text:'Our 100% virtual program gives you the flexibility to learn from anywhere in the world'
                    },
                    {
                        id:6,
                        title:'CERTIFICATE',
                        icon:tool1,
                        text:'Earn a certificate that validates your expertise and dedication.'
                        },
]

const facilitators = [
    {
    id:1,
    title:'Product Lead',
    icon:sarah,
    name:'Sarah Oshunniyi'
    },
    {
      id:2,
      title:'Product Manager',
      icon:fara,
      name:"Far'hah Alade-Raji"
      },
    
                            {
                              id:3,
                              title:'Product Manager',
                              icon:olanike,
                              name:'Omotoyosi Atunrase'
                              },
                            
                              
]

const Trainings = () => {
    const [loading, setLoading] = useState(false);
    const navigate=useNavigate();
    const [formLoading, setFormLoading] = useState(false);
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        laptop: '',
        resume: '',
        message: '',
      });
    const {showToast,isMobile}= useAppContext()
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    const handleNextReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex >= reviews.length - 1 ? 0 : prevIndex + 1));
      };
    
      const handlePreviousReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex <= 0 ? reviews.length - 1 : prevIndex - 1));
      };
    
      const currentReview = reviews[currentReviewIndex];

    const scrollToSection = () => {
        const section = document.getElementById('section5');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const sendEmail = (e) => {
        e.preventDefault();
  
        setLoading(true)
    
        const formState = new formState(e.target);
        const templateParams = {
          from_name: formState.get('name'),
          from_email: formState.get('email'),
          subject: formState.get('subject'),
          message: formState.get('message'),
        };
    
        emailjs
          .send('service_qz0n8ka', 'template_e5w27pg', templateParams, 'OWGDWJiOiLcjPehLx')
          .then((response) => {
            setLoading(false)
          
            showToast({toastText:'Message sent successfully', isSuccess:true})
            e.target.reset();
           
          })
          .catch((error) => {
            setLoading(false)
         
            showToast({toastText:'Failed to send message. Please try again', isSuccess:false})
  
          });
          
      };

      const handleFocus = (e) => {
        e.target.placeholder = "";
    };
    
    const handleBlur = (e) => {
        if (!formState.message) {
            e.target.placeholder = 'Why do you want to train with us? Answer this as uniquely as possible, we are looking for extraordinary people to work with us';
        }
    };

      const handleChange = (e) => {
        const { name, value} = e.target;
    
        // Handle phone number validation
        if (name === 'phoneNumber') {
          if (!/^\d*$/.test(value) || value.length > 11) return;
        }
    
        // // Handle file input validation
        // if (name === 'resume' && type === 'file') {
        //   const file = files[0];
        //   if (file && file.size > 10 * 1024 * 1024) { // File size greater than 10MB
        //     alert('Resume file size must not exceed 10MB.');
        //     return;
        //   }
        //   setFormState((prev) => ({ ...prev, [name]: file }));
        //   return;
        // }
    
        // Generic state update
        setFormState((prev) => ({ ...prev, [name]: value }));
      };
    
      
      const handleSubmit = async(e) => {
        e.preventDefault();
        console.log(formState);
        // const formActionURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScG07vkOBGGzNQcKNG5u6hjT-Al9SZjgadr_lE8p9f7uyWlsA/formResponse';
        const formActionURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfYqSzUB0783OHxkQm2p36tt9pwR9UrEiRvb6qh5lIZD4gV1Q/formResponse';

        const firstNameID= "entry.1781500597";
      const lastNameID= "entry.981464036";
      const emailID= "entry.1640447617";
      const phoneNumberID= "entry.297979220";
    const resumeID= "entry.2040870261";
     
      const messageID= "entry.1960932695";
      // Single Produce
      const laptopID= "entry.1078004677";
    
    //   const firstNameID= "entry.1090488747";
    //   const lastNameID= "entry.1701957145";
    //   const emailID= "entry.235468616";
    //   const phoneNumberID= "entry.1262454611";
    //   const resumeID= "entry.211434196";
     
    //   const messageID= "entry.2026317223";
    //   // Single Produce
    //   const laptopID= "entry.305022085";
     
    // Create a new FormData object
     const formData = new FormData();
    // const formData = new URLSearchParams();
    formData.append(firstNameID, formState?.firstName);
    
    formData.append(lastNameID, formState.lastName);
    formData.append(emailID, formState.email);
    formData.append(phoneNumberID, formState.phoneNumber);
    formData.append(laptopID, formState.laptop);
    formData.append(resumeID, formState.resume);
    formData.append(messageID, formState.message);
   
    
  setFormLoading(true)
//     try {
//       // Make a POST request with the formState
//       const response = await fetch(formActionURL, {
//         method: 'POST',
//         mode: 'no-cors', // Required to avoid CORS errors, but note that response will be opaque
//         body: formData,
//       });
//       setFormLoading(false);
     

//  console.log(response,'response')
     
//     } catch (error) {
//       console.error('Form submission error:', error);
//       setFormLoading(false)
     

//     }
//   };
try {
    const response= await fetch(formActionURL, {
        method: 'POST',
        mode: 'no-cors', 
        body: formData,
      
    });
    if(response){
        setFormLoading(false)

    alert('Form submitted successfully!');
   setFormState({});
   navigate('/payment')
    }

  } catch (error) {
    console.error('Error submitting form', error);
  }
};
        return (
    <div style={{overflowX:'hidden', fontFamily:'Inter'}} className='my-app training-body' >
   
   <section className='pt-4 pt-md-5 position-relative' style={{paddingBottom:isMobile ? '0px': '150px'}} >
        <div className='gradient-patch-1' />
        
        <div className='gradient-patch-2' />
            <div className='gradient-patch-3' />
           
            <Card style={{backgroundColor:'transparent', border:'none',  borderRadius:'0px', color:'white'}} className='h-100'>
        
         <img src={arrowGiphy} alt='giphy'className='position-absolute register-giphy' style={{
angle: '-63.05 deg', pointerEvents: 'none',   
transform:'rotate(45deg) scaleY(-1)',zIndex:'1'

}}/> 
<div className='mt-xl-5 pt-5 pb-2 pb-md-0 mb-md-0'>

      <Row className='mt-5 mb-3 mb-md-5 pt-md-5 px-3 px-md-5'>

        <Col xs={12} md={7} className='d-flex flex-column gap-2 gap-md-3  pe-md-5 mb-3 mb-md-0 pb-0 '>
        <div className='gradient-text mb-3' style={{fontSize:isMobile ? '28px':'48px', lineHeight:isMobile ? '30px':'58px',}}>Become a Product Management PRO- Learn, Build and Get Hired!
            </div>
            <h3 style={{fontWeight:'400',color:'#E9EBF8'}}>4 weeks of Intensive Training. 4 weeks of Internship. A Chance to Land a Full-Time Role</h3>
            <div className='mt-1 mt-md-5' id="navbar-example2">
            <Button className='btn btn-primary ' onClick={scrollToSection} style={{borderRadius:"50px", fontSize:isMobile ? '18px':'30px', padding:isMobile ? '12px': "24px", background: 'linear-gradient(95.21deg, #FFB74D 13.07%, #D7A558 91.73%, #FFB74D 112.55%)'
}}>Register Now</Button></div>
       
        </Col>
        <Col xs={12} md={5} className='ps-md-3 pe-md-5 mb-5 mb-md-0 pb-0'>
            <Card.Img src={training} alt='training'/>
        </Col>
      </Row>
      
        </div>

</Card>
       
        </section>
        <section style={{backgroundColor:'#1B1B1B'}} className='pt-3 pt-md-5 px-3 pb-3 px-md-5 position-relative'>
        <img src={wave3} className='position-absolute wave3' style={{ position:'absolute', right:'0', top:'0',  pointerEvents: 'none', }}/>
        <h2 className='text-center gradient-text mb-3' >Benefits
        </h2>
        <div className='position-relative'>
<div style={{borderBottom:'3px solid white', width:'200px'}}></div>
<Row className='my-3 my-md-4'>
   

{benefits.map((benefit, index) => {
    // Assign gradient based on even or odd index
    const gradient = index % 2 === 0
        ? 'linear-gradient(180deg, #FFB74D 0%, #818181 100%)' // Original gradient for even indexes
        : 'linear-gradient(180deg, #6BA4D8 0%, #818181 100%)'; // Variation for odd indexes

    return (
        <Col xs={12} sm={6} lg={4} key={benefit.id} className='mb-4 pe-lg-4'>
        <Card
            key={index}
            style={{
                background: gradient,
                border: 'none',
                borderRadius: '12px',
            }}
            className="px-3 pt-3 pb-5 d-flex flex-column gap-3 h-100"
        >
            <img
                src={benefit.icon}
                alt="Benefit Icon"
                style={{ width: '48px', height: '48px' }}
            />
            <h3
                style={{
                    fontFamily: 'Nunito Sans',
                    color: '#E9EBF8',
                    textTransform: 'uppercase',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
                {benefit.title}
            </h3>
            <h6
                style={{
                    fontFamily: 'Nunito Sans',
                    color: '#E9EBF8',
                    lineHeight: '24px',
                    fontWeight: '400',
                }}
            >
                {benefit.text}
            </h6>
        </Card>
        </Col>
    );
})}

</Row>

</div>
        </section>

        <section className='position-relative pt-3 pt-md-5 pb-3 px-3 px-md-5'>

<img src={technologyGiphy} className='position-absolute technology-giphy'alt='giphy' style={{left:'30px',
angle: '173.05 deg', pointerEvents: 'none', 
}}/>
<img src={giphy} className='position-absolute'alt='giphy' style={{right:'5%',
top: '77%', position:'absolute', pointerEvents: 'none', 
}}/>
<div className=''><h2 className='text-center gradient-text'>Alumni Testimonials</h2>
<div className='mt-5 position-relative d-block d-md-flex gap-3 px-lg-4 pb-3 pb-md-5'> 


<div className=' d-none d-md-flex align-items-center '>
<Card style={{border:'none', background:'transparent'}} className=''>
  <Card.Img src={currentReview.image} className='client' style={{maxWidth:'380px',minHeight:'200px', maxHeight:'512px'}}/>
  <Card.ImgOverlay className='p-0 d-flex align-items-end'>
      <div className='d-flex flex-column  text-white p-3'  style={{ backgroundColor: '#1B1B1B', border: '1px solid #DADADA33', maxWidth:'250px' }}> <h4 style={{fontWeight:'700'}}>{currentReview.name}</h4>
      <h6 style={{fontWeight:'600'}} className='mb-0 pb-1'>{currentReview.title}</h6>
     <img src={rate} className='rate' /></div>
     
   
  </Card.ImgOverlay>

</Card>
<div className='idea' style={{
      padding: '1px',
      background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
      borderRadius: '13px',
      maxWidth:'630px',
      zIndex:'100'
  }}>
      <Card style={{
              backgroundColor: "#1B1B1B",
              borderRadius: '12px', 
             
          }}className='py-2 py-lg-4 text-white'>
             
              <div className='ms-5 me-5 '>
                  <h3 style={{fontFamily:'Nunito Sans', fontWeight:'800'}} className='mb-3'>{currentReview?.testimonyTitle}</h3>
                  <h4 style={{fontFamily:'Nunito Sans', fontWeight:'400 !important'}}>{currentReview?.testimony}</h4>
              </div>
              


          </Card>
          </div>
         
</div>
<div className='d-block d-md-none '>
      <div style={{
      padding: '1px',
      background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
      borderRadius: '13px', 
  }} className=''>
      <Card style={{
              backgroundColor: "#1B1B1B",
              borderRadius: '12px', 
             
          }} className='p-3 p-md-4 text-white'>
              <div className='d-flex align-items-center gap-3'>
                <div>  <Image fluid src={currentReview.image} style={{maxWidth:'150px', minWidth:'150px'}}/>  <div className='d-flex flex-column mt-1 text-white ' style={{}}> <h6 style={{fontWeight:'700'}} className='mb-0 pb-1'>{currentReview.name}</h6>
      <p style={{fontWeight:'600'}} className='mb-0 pb-1'>{currentReview.title}</p>
     <img src={rate} className='' style={{width:'65px'}} /></div></div>
             
              <div>

                  <h4 style={{fontWeight:'600'}} className='mt-3 mb-2'>{currentReview?.testimonyTitle}</h4>
                  <p style={{fontWeight:'400'}} >{currentReview?.testimony}</p>
              </div>
              </div>
          </Card>
          </div>
      </div>

{reviews.length > 1 && 
<div className="d-flex gap-3 justify-content-center mt-5 mt-md-0">
  <button
    className="prev-buttons"
    onClick={handlePreviousReview}
    
  >
    <img src={arrowRight} />
  </button>
  <button
    className='next-buttons'
    onClick={handleNextReview}
   
  >
   <img src={arrowRight} style={{transform: 'rotate(180deg)'}}/>
  </button>
</div>
}
</div>
</div>



</section>
<section className='position-relative pt-3 pt-md-5 pb-3 pb-md-5 px-3 px-md-5'>


<h2 className='text-center gradient-text'>Product Management Curriculum</h2>
<h3 style={{fontStyle:'italic', fontWeight:'200', letterSpacing:"1px"}} className='text-center mt-2'>The training will start on the 14th of January, top trainees will qualify for 4 weeks internship. </h3>
<div className='mt-4 mt-md-5 position-relative px-lg-4 pb-3 pb-md-5 big-gap'> 
<Row className=''>
{curriculum.map((cur,index)=> (
<Col xs={6} key={index} className='mb-1'>
<div  style={{
      padding: '1px',
      background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
      borderRadius: '12px',
  }}>
      <Card style={{
              backgroundColor: "#1B1B1B",
              borderRadius: '12px', 
             
          }}className='p-2 text-white'>
            {cur}
          </Card>
            
        </div>
           </Col>
))}
      
      </Row>
      <Row className='my-3 my-md-5'>
      <Col xs={12} lg={6} className='mb-3'>
        <Card
            
            style={{
                background: "linear-gradient(180deg, #6BA4D8 0%, #818181 100%)",
              border: '1px solid',

borderImageSource: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
                borderRadius: '12px',
            }}
            className="px-3 py-3 d-flex flex-column h-100 text-center"
        >
            <h1
                style={{
                    fontSize: isMobile ? '28px' :'52px',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
                N100,000
            </h1>
            <h3
                style={{
                    fontSize: isMobile ? '20px' :'36px',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
               Early bird
            </h3>
            <h5  style={{
                    fontWeight: '700',
                }}  className="mt-3 mt-md-5">6 weeks | Jan 25</h5>
        </Card>
        </Col>
        <Col xs={12} lg={6} className='mb-3'>
        <Card
            
            style={{
                background: "linear-gradient(180deg, #FFB74D 0%, #818181 100%)",
                border: '1px solid',

borderImageSource: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
                borderRadius: '12px',
            }}
            className="px-3 py-3 d-flex flex-column h-100 text-center"
        >
            <h1
                style={{
                    fontSize: isMobile ? '28px' :'52px',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
                N150,000
            </h1>
            <h3
                style={{
                    fontSize: isMobile ? '20px' :'36px',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
               Actual fee
            </h3>
            <h5  style={{
                    fontWeight: '700',
                }}  className="mt-3 mt-md-5">6 weeks | Jan 25</h5>
        </Card>
        </Col>
      </Row>
    </div>
    </section>
    <section className='py-md-5 py-4 px-3 px-lg-5 position-relative' style={{backgroundColor:'#1B1B1B'}} id='section5'>
     
     <img src={wave4} className='position-absolute wave3' style={{position:'absolute', bottom:'0', left:0,  pointerEvents: 'none', }}/>
     <img src={wave3} className='position-absolute wave3' style={{ position:'absolute', right:'0', top:'0',  pointerEvents: 'none', }}/>
     <h2 className='gradient-text text-center' >Application Form</h2>
     <h3 style={{fontStyle:'italic', fontWeight:'200', letterSpacing:"1px"}} className='text-center mt-2'>Applications will close January 3rd, the program will start January 14th. Only 20 slots available so APPLY NOW and secure your spot. Will you be the one to qualify for a FULL TIME ROLE with us? </h3>
     {formLoading ? 
   (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
   <div className="spinner-border text-secondary" role="status">
   <span className="visually-hidden">Loading...</span>
   </div>
   </div>):

<Form className='mt-5 mb-3 mb-md-5 text-white pb-lg-3 position-relative'  onSubmit={handleSubmit}>
 <Row>
   <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} >
       <Form.Control name="firstName"   value={formState.firstName}
              onChange={handleChange} style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='First Name' className='py-3' required />
     </div>
     </Col>
     <Col xs={12} md={6} className='mb-3 mb-md-5'>
   <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }}>
       <Form.Control name="lastName" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Last Name'   value={formState.lastName}
              onChange={handleChange} className='py-3' required />
     </div>
     </Col>
     <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} className=''>
       <Form.Control name="email" required style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Email Address' type='email' className='py-3'   value={formState.email}
              onChange={handleChange}/>
     </div>
     </Col>
     <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} >
       <Form.Control name="phoneNumber" required style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Phone Number' type='text' className='py-3'    value={formState.phoneNumber}
              onChange={handleChange}/>
     </div>
     </Col>
     <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }}>
     
       <Form.Select name="laptop" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white', fontWeight:'400' }} className='py-3'     required value={formState.laptop}
              onChange={handleChange}>
         <option value="" hidden>  Do you have a working laptop for the training?</option>
         <option value="Yes">Yes</option>
         <option value="No">No</option>
       </Form.Select>
     </div>
     </Col>
    
   
    
     <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} >
       <Form.Control name="resume" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Attach a link to your resume. Ensure it is publicly accessible' type='text'  onChange={handleChange} className='py-3' required />
     </div>
   </Col>
   <Col xs={12} md={6} className='mb-3 mb-md-5'>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }}>
       <textarea  name="message"
    style={{
        backgroundColor: '#1B1B1B',
        borderRadius: '7px',
        border: 'none',
        color: 'white'
    }}
    placeholder='Why do you want to train with us? Answer this as uniquely as possible, we are looking for extraordinary people to work with us'
    type='text'
    className='py-3 w-100 h-100'
    required
    rows={5}
    onFocus={handleFocus}
    onBlur={handleBlur}
    onChange={handleChange}
    value={formState.message}/>
     </div>
   </Col>
 </Row>
 <div className='text-center mt-3'>
   <Button type="submit" style={{width: isMobile ? '50%':'20%'}}>Submit & Pay</Button>
 </div>

</Form>
}

     </section>
    <section className='py-md-5 py-4 px-3 px-lg-5 position-relative team'>
        <h2 className='gradient-text text-center' > Meet The Facilitators</h2>
        <Row className='mt-md-5 mt-4'>
        {facilitators.map((team, index) => (
          <Col key={team.id} xs={4} md={4} className='mb-4 px-lg-5 h-100'>
            <Card style={{borderRadius:'50%', objectFit:'cover', 
            backgroundColor:'transparent',
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',

}}>
    <div className='justify-content-center' style={{backgroundColor:'#1B1B1B',borderRadius:'50%', objectFit:'cover', height:isMobile ? '130px':'300px'}}>
              <Image src={team.icon}  className='' style={{borderRadius:'50%', objectFit:'cover',height:isMobile ? '130px':'300px', width:'100%'

}}/></div>
</Card>
              
                <div className='text-center mt-2'>
                  <h4 style={{ fontFamily: 'Nunito Sans', fontWeight: '700', color: '#E9EBF8' }}>{team.name}</h4>
                  <h5 style={{ fontFamily: 'Nunito Sans', fontWeight: '400', color: '#E9EBF8' }} className='mt-2'>{team.title}</h5>
                </div>
             
           
          </Col>
        ))}
      </Row>
     
      </section>
    <section className='py-md-5 py-4 px-3 px-lg-5 position-relative' style={{backgroundColor:'#1B1B1B'}}>
     
     <img src={wave4} className='position-absolute wave3' style={{position:'absolute', bottom:'0', left:0,  pointerEvents: 'none', }}/>
     <img src={wave3} className='position-absolute wave3' style={{ position:'absolute', right:'0', top:'0',  pointerEvents: 'none', }}/>
     <h2 className='gradient-text text-center' >Enquiries?</h2>
     {loading ? 
   (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
   <div className="spinner-border text-secondary" role="status">
   <span className="visually-hidden">Loading...</span>
   </div>
   </div>):

<Form className='my-5 text-white pb-lg-3 position-relative' onSubmit={sendEmail}>
 <Row>
   <Col xs={12} md={6}>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} className='mb-4'>
       <Form.Control name="name" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Name' className='py-3' required />
     </div>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} className='mb-4'>
       <Form.Control name="email" required style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Email address' type='email' className='py-3' />
     </div>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} className='mb-4 mb-md-0'>
     
       <Form.Select name="subject" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} className='py-3' required>
         <option value="" hidden> Subject</option>
         <option value="Enquiry">Enquiry</option>
         <option value="Careers">Careers</option>
       </Form.Select>
     </div>
   </Col>
   <Col xs={12} md={6}>
     <div style={{
       padding: '1px',
       background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
       borderRadius: '8px',
       
     }} className='mb-4 h-100'>
       <textarea name="message" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Message' type='text' className='py-3 w-100 h-100' required></textarea>
     </div>
   </Col>
 </Row>
 <div className='d-flex justify-content-end mt-4 pe-5 me-lg-5'>
   <Button type="submit">Let's Chat</Button>
 </div>

</Form>
}

     </section>
    </div>

  )
  
}

export default Trainings
