
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import wave3 from '../assets/wave3.svg'
import wave4 from '../assets/wave4.svg'
import arrowRight from '../assets/arrow-right.svg'
import { useAppContext } from '../AppContext'
import { useNavigate } from 'react-router-dom'

const Account = () => {
    const {isMobile}= useAppContext();
    const navigate = useNavigate();
  return (
    <div style={{overflowX:'hidden', fontFamily:'Inter,',position:'relative'}} className='my-app' >
    <section className='   px-3 px-md-5' style={{paddingTop:isMobile ? '175px':'50px', paddingBottom:isMobile ? '200px':'50px'}}>
 <img src={wave4} className='position-absolute ' style={{position:'absolute', bottom:'0', left:0,  pointerEvents: 'none', }}/>
 <img src={wave3} className='position-absolute' style={{ position:'absolute', right:'0', top:'0',  pointerEvents: 'none', }}/>
<Button onClick={()=> navigate(-1)}><img src={arrowRight} alt='arrow'/></Button>
<h2 className='text-center gradient-text '>Payment</h2>
<h3 style={{fontStyle:'italic', fontWeight:'200', letterSpacing:"1px",maxWidth:"57rem"}} className='text-center mx-auto mt-4'>Payment validates your application. Once you have transferred to the account details below, kindly send the proof to admin@trysaleswave.com</h3>


        <Card
            
            style={{
                background: "#FFB74D",
                border: 'none',
maxWidth:'33rem',
                borderRadius: '5px',
            }}
            className="px-3 py-3 d-flex flex-column  gap-2 text-center mx-auto my-5"
        >
            <h1
                style={{
                    fontSize: isMobile ? '28px' :'52px',
                    fontWeight: '700',
                }}
                className="mt-1"
            >
                N100,000
            </h1>
            <h1
               style={{
                fontSize: isMobile ? '28px' :'52px',
                fontWeight: '700',
            }}
            className="mt-4"
            >
               4475764123
            </h1>
            <h3  style={{
                    fontWeight: '700',
                }}  className="mt-3"> 
                 SalesWave Digital Services LTD<br/>
                Moniepoint MFB</h3>
        </Card>
        <div className='text-center'>
        <Button  href="mailto:admin@trysaleswave.com"  target="_blank"  
            rel="noopener noreferrer"
            className="px-5"  > Send Proof</Button>
      </div>
    
    </section>
    </div>
  )
}

export default Account
