import React, { useState, useEffect } from 'react'
import logo from '../assets/SalesWave.svg'
import { Col, Row, Form, Button } from 'react-bootstrap'
import arrowGiphy from '../assets/arrow-giphy.svg'
import emailjs from 'emailjs-com';
import { useAppContext } from '../AppContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
    const [loading, setLoading] = useState(false);
    const {showToast}= useAppContext()
    const [sectionToScroll, setSectionToScroll] = useState(null);
 
    const location = useLocation();
    const navigate = useNavigate();
    const sendEmail = (e) => {
        e.preventDefault();
  
        setLoading(true)
    
        const formData = new FormData(e.target);
        const templateParams = {
         
          from_email: formData.get('email'),
          
        };
    
        emailjs
          .send('service_qz0n8ka', 'template_esv0a05', templateParams, 'OWGDWJiOiLcjPehLx')
          .then((response) => {
            setLoading(false)
          
            showToast({toastText:'You have subscribed successfully', isSuccess:true})
            e.target.reset();
           
          })
          .catch((error) => {
            setLoading(false)
         
            showToast({toastText:'Failed to subscribe. Please try again', isSuccess:false})
  
          });
          
      };

      const scrollToSection = (sectionId) => {
        const handleScroll = () => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        };
    
        if (location.pathname === '/') {
          handleScroll();
        } else {
          setSectionToScroll(sectionId);
         navigate('/');
        }
      };
    
      useEffect(() => {
        if (location.pathname === '/' && sectionToScroll) {
          const section = document.getElementById(sectionToScroll);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
          setSectionToScroll(null);
        }
      }, [location.pathname, sectionToScroll]);

  return (
    <div className='position-relative my-app'>
        <img src={arrowGiphy} alt='giphy'className='position-absolute footer-arrow' style={{
angle: '59.36 deg', 
}}/>
    <div className='pt-5 pb-4 px-xl-5 px-4'>
        <div className='mt-5 px-xl-3' style={{color:'#E9EBF8'}}>
            <img src={logo} alt='' className='sw-logo'/>
            <Row className='mt-5 mb-3 mb-lg-4'>
                <Col xs={6} lg={3} className='mb-4'>
                    <div className='d-flex flex-column gap-2' style={{fontFamily:'Nunito Sans !important'}}>
                        <h3 style={{fontFamily:'Nunito Sans', fontWeight:'600'}} >Company</h3>
                        <div className='d-flex flex-column gap-1'>
                        <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a className="text-decoration-none" onClick={() => scrollToSection('section1')} style={{color:'#E9EBF8'}}>Our Projects</a></h5>
                             <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a onClick={() => scrollToSection('section2')} className="text-decoration-none" style={{color:'#E9EBF8'}}>Our Services</a></h5>
                            <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a onClick={() => scrollToSection('section3')} className="text-decoration-none" style={{color:'#E9EBF8'}}>Our Clients</a></h5>
                             <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a onClick={() => scrollToSection('section4')} className="text-decoration-none" style={{color:'#E9EBF8'}}>Our Team</a></h5>
                        </div>
                    </div>

                </Col>
                <Col xs={6} lg={3} className='mb-4'>
                    <div className='d-flex flex-column gap-2' style={{fontFamily:'Nunito Sans', fontWeight:''}}>
                        <h3 style={{fontFamily:'Nunito Sans', fontWeight:'600'}}>Legal</h3>
                        <div className='d-flex flex-column gap-1'>
                            <h5 style={{fontFamily:'Nunito Sans', fontWeight:'400'}}>Privacy Policy</h5>
                            <h5 style={{fontFamily:'Nunito Sans', fontWeight:'400'}}>Terms of Services</h5>
                            <h5 style={{fontFamily:'Nunito Sans', fontWeight:'400'}}>FAQs</h5>
                          
                        </div>
                    </div>

                </Col>
                <Col xs={6} lg={3} className='mb-4'>
                    <div className='d-flex flex-column gap-2' style={{fontFamily:'Nunito Sans', fontWeight:''}}>
                        <h3 style={{fontFamily:'Nunito Sans', fontWeight:'600'}}>Socials</h3>
                        <div className='d-flex flex-column gap-1'>
                        <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a href='https://x.com/trysaleswave?s=21'  target="_blank"  
            rel="noopener noreferrer" className="text-decoration-none" style={{color:'#E9EBF8'}}>Twitter</a></h5>
                             <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a  className="text-decoration-none" style={{color:'#E9EBF8'}}>Facebook</a></h5>
                            <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a href='https://www.instagram.com/trysaleswave_?igsh=NWRlMDc1Zm1oeGM1&utm_source=qr'  target="_blank"  
            rel="noopener noreferrer"className="text-decoration-none" style={{color:'#E9EBF8'}}>Instagram</a></h5>
                             <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a href='https://www.linkedin.com/company/trysaleswave/'  target="_blank"  
            rel="noopener noreferrer" className="text-decoration-none" style={{color:'#E9EBF8'}}>LinkedIn</a></h5>
                        </div>
                    </div>

                </Col>
                <Col xs={6} lg={3} className='mb-4 '>
                    <div className='d-flex flex-column gap-2' style={{fontFamily:'Nunito Sans', fontWeight:'400'}}>
                        <h3 style={{fontFamily:'Nunito Sans', fontWeight:'600'}}>Contact</h3>
                        <div className='d-flex flex-column gap-1'>
                        <h5 className='' style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400' }}
            ><a href="tel:+2349167682871" className="text-decoration-none" style={{color:'#E9EBF8'}}>+2349167682871</a></h5>
                            <h5  style={{ cursor: "pointer",fontFamily:'Nunito Sans', fontWeight:'400'}}
           > <a
            href="mailto:admin@trysaleswave.com"  target="_blank"  
            rel="noopener noreferrer"
            className=""  style={{color:'#E9EBF8'}}
          >admin@trysaleswave.com
          </a></h5>
                        </div>
                    </div>

                </Col>
            </Row>
            {loading ? 
    (<div className='' style={{padding:''}}>
    <div className="spinner-border text-secondary" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
          <Form className='' onSubmit={sendEmail}>
                <h5 style={{fontFamily:'Nunito Sans', fontWeight:'400'}}>Get Our Newsletter</h5>
                <div className='d-flex gap-3 align-items-center mt-1'>
                <div style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '32px',
          
        }}   className='mail'>  <Form.Control
        style={{ backgroundColor: '#111111', borderRadius: '31px', border: 'none', color: 'white' }}
        placeholder='Enter mail'
        type='email'
        name='email'
        className='py-3 '
      />

        </div>

                    <Button type='submit'>Subscribe</Button>
                </div>
            </Form>
}

        </div>
        </div>
        <div className='d-flex align-items-center text-center justify-content-center py-3 px-3 px-md-5' style={{borderTop:'1px solid #DADADA66'}}>
       
       <p className='my-auto 'style={{fontFamily:'Outfit', fontWeight:'500', color:'#E9EBF8'}}>&copy; &nbsp;Copyright Saleswave 2024</p>
       </div>
          
      
   
    </div>
  )
}

export default Footer
